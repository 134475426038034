<template>
  <v-card id="account-setting-card">
    <div id="installapp" style="padding: 30px">
      <h1>SMS</h1>
    </div>

    <div class="container mx-auto">
      <div class="min-w-full border rounded lg:grid lg:grid-cols-3">
        <div class="border-r border-gray-300 lg:col-span-1">
          <div class="mx-3 my-3">
            <div class="relative text-gray-600">
              <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  class="w-6 h-6 text-gray-300"
                >
                  <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
              </span>
              <input
                type="search"
                class="block w-full py-2 pl-10 bg-gray-100 rounded outline-none"
                name="search"
                placeholder="Search"
                required
              />
            </div>
          </div>

          <ul class="overflow-auto h-[32rem]" v-if="receiptList.length > 0">
            <h2 class="my-2 mb-2 ml-2 text-lg text-gray-600">Chats</h2>
            <li v-for="item in receiptList" :key="item.threadId">
              <a
                @click="getConversation(item)"
                class="
                  flex
                  items-center
                  px-3
                  py-2
                  text-sm
                  transition
                  duration-150
                  ease-in-out
                  border-b border-gray-300
                  cursor-pointer
                  hover:bg-gray-100
                  focus:outline-none
                "
              >
                <img
                  class="object-cover w-10 h-10 rounded-full"
                  :src="require('@/assets/images/avatars/1.png')"
                  alt="username"
                />
                <div class="w-full pb-2">
                  <div class="flex justify-between">
                    <span class="block ml-2 font-semibold text-gray-600">{{ item.address }}</span>
                    <span class="block ml-2 text-sm text-gray-600">{{ item.date }}</span>
                  </div>
                  <span class="block ml-2 text-sm text-gray-600">{{ item.msg }}</span>
                </div>
              </a>
              <!-- <a
                class="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out bg-gray-100 border-b border-gray-300 cursor-pointer focus:outline-none">
                <img class="object-cover w-10 h-10 rounded-full"
                  src="https://cdn.pixabay.com/photo/2016/06/15/15/25/loudspeaker-1459128__340.png" alt="username" />
                <div class="w-full pb-2">
                  <div class="flex justify-between">
                    <span class="block ml-2 font-semibold text-gray-600">Same</span>
                    <span class="block ml-2 text-sm text-gray-600">50 minutes</span>
                  </div>
                  <span class="block ml-2 text-sm text-gray-600">Good night</span>
                </div>
              </a>
              <a
                class="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                <img class="object-cover w-10 h-10 rounded-full"
                  src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg" alt="username" />
                <div class="w-full pb-2">
                  <div class="flex justify-between">
                    <span class="block ml-2 font-semibold text-gray-600">Emma</span>
                    <span class="block ml-2 text-sm text-gray-600">6 hour</span>
                  </div>
                  <span class="block ml-2 text-sm text-gray-600">Good Morning</span>
                </div>
              </a> -->
            </li>
          </ul>
        </div>
        <div class="hidden lg:col-span-2 lg:block">
          <div class="w-full">
            <div class="relative flex items-center p-3 border-b border-gray-300">
              <img
                class="object-cover w-10 h-10 rounded-full"
                :src="require('@/assets/images/avatars/1.png')"
                alt="username"
              />
              <span class="block ml-2 font-bold text-gray-600">Emma</span>
              <span class="absolute w-3 h-3 bg-green-600 rounded-full left-10 top-3"> </span>
            </div>
            <div class="relative w-full p-6 overflow-y-auto h-[40rem]">
              <ul class="space-y-2" v-for="item in smsList" :key="item.length">
                <li class="flex justify-start" v-if="item.type == 1" style="margin: 10px">
                  <div class="relative max-w-xl px-4 py-2 text-gray-700 rounded shadow">
                    <span class="block">{{ item.msg }}</span>
                  </div>
                </li>
                <li class="flex justify-end" v-if="item.type == 2" style="margin: 10px">
                  <div class="relative max-w-xl px-4 py-2 text-gray-700 bg-gray-100 rounded shadow">
                    <span class="block">{{ item.msg }}</span>
                  </div>
                </li>
              </ul>
            </div>

            <div class="flex items-center justify-between w-full p-3 border-t border-gray-300">
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                  />
                </svg>
              </button>

              <input
                type="text"
                placeholder="Message"
                class="block w-full py-2 pl-4 mx-3 bg-gray-100 rounded-full outline-none focus:text-gray-700"
                name="message"
                required
              />
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                  />
                </svg>
              </button>
              <button type="submit">
                <svg
                  class="w-5 h-5 text-gray-500 origin-center transform rotate-90"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>
<style scoped>
</style>
<script>
import { doc, getDoc } from 'firebase/firestore'
import { dbRef, smsClick } from '../../../../firebaseConfig'
import { getUID, getCurrentDeviceSelected } from '../../../../localStorage'
export default {
  name: 'Sms',
  data() {
    return {
      search: '',
      myitems: [],
      receiptList: [],
      smsList: [],
      headers: [
        {
          text: 'App name',
          align: 'start',
          sortable: false,
          value: 'appname',
        },
        { text: 'Package Name', value: 'pname' },
        { text: 'Version Code', value: 'versionCode' },
        { text: 'Version Name', value: 'versionName' },
      ],
      desserts: [
        {
          deviceID: 'Frozen Yogurt',
          durations: 159,
          name: 6.0,
          carbs: 24,
          number: 4.0,
          time: '1%',
        },
      ],
    }
  },
  mounted: function () {
    //  this.tabeRe();
    smsClick()
    this.getSMSList()
  },
  methods: {
    async getConversation(e) {
      console.log(e)
      this.smsList = this.myitems.find(ee => ee.threadId == e.threadId)
      this.smsList = this.smsList.smsList.reverse()
      console.log(this.myitems.find(ee => ee.threadId == ee.threadId))
      console.log(this.smsList.smsList)
    },
    async getSMSList() {
      this.$store.commit('LOADER', true)
      let uid = getUID()
      const myDoc = doc(dbRef, 'sms', uid, getCurrentDeviceSelected(), uid + '-' + getCurrentDeviceSelected())
      getDoc(myDoc)
        .then(res => {
          let listOfApps = res.data()
          console.log(listOfApps)
          Object.values(listOfApps).map(i => {
            this.myitems.push(i)
            let objRequest = {
              threadId: i.threadId,
              address: i.smsList[0].address,
              date: i.smsList[0].date,
              msg: i.smsList[0].msg,
            }

            this.receiptList.push(objRequest)
          })
          console.log(this.myitems)
          console.log(this.receiptList)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
  },
}
</script>